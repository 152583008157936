//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { Dialog } from 'vant';
import { sendEmailCode } from '@/api/login';
import { param2Obj } from '@/utils/help';
import { ERROR_OK } from '@/api/config';

Vue.use(Dialog);
const COUNT = 60;
export default Vue.extend({
  name: 'Login',
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || this.$t('login_page_label1'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login_page_label2'),
      ],
      code: '',
      codeRules: [
        (v) => v.length === 4 || this.$t('login_page_label3'),
      ],
      codeDisabled: false,
      buttonText: this.$t('login_page_btn1'),
      count: COUNT,
      timer: null,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      // eslint-disable-next-line
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    const { query } = this.$route;
    if (query.email) {
      this.email = decodeURIComponent(query.email);
      this.initEmail = true;
    }
  },
  mounted() {
    if (this.initEmail) {
      this.$nextTick(() => {
        this.$refs.code.focus();
      });
    }
  },
  methods: {
    handleSendCode() {
      if (!this.email) {
        this.$refs.email.focus();
        return;
      }
      this.buttonText = 'loading';
      this.codeDisabled = true;
      sendEmailCode({ email: this.email }).then((res) => {
        if (res.code === ERROR_OK) {
          Dialog.alert({
            title: res.data.verifiedRes1,
            confirmButtonText: res.data.verifiedRes3,
            message: res.data.verifiedRes2,
          }).then(() => {
            // on close
          });
          if (!this.codeDisabled) {
            this.codeDisabled = true;
          }
          this.start();
        } else {
          this.buttonText = 'send code';
          this.codeDisabled = false;
        }
      }).catch(() => {
        this.buttonText = 'send code';
        this.codeDisabled = false;
      });
    },
    start() {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.buttonCount();
      }, 1000);
      this.buttonCount();
    },
    stop() {
      this.timer && clearInterval(this.timer);
      delete this.timer;
      this.count = COUNT;
      this.buttonText = 'send code';
      this.codeDisabled = false;
    },
    buttonCount() {
      if (this.count) {
        this.buttonText = `${this.$t('login_page_btn2')}(${this.count})`;
      } else {
        this.stop();
      }
      this.count -= 1;
    },
    cleanArray(actual) {
      const newArray = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    },
    param(json) {
      if (!json) return '';
      return this.cleanArray(
        Object.keys(json).map((key) => {
          if (json[key] === undefined) return '';
          return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
        }),
      ).join('&');
    },
    submit() {
      if (this.$refs.form.validate()) {
        const { redeemCode, ...data } = param2Obj(window.location.search);
        this.$store.dispatch('user/login', { account: this.email, code: this.code, redeemCode })
          .then(() => {
            window.location.search = this.param(data);
            this.$router.push({ path: this.redirect || '/products' });
          });
      }
    },
  },
});
